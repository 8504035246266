import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import "./search.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { axiosInstace } from "../../service/axiosConfig";
import { makeStyles } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { IconButton } from "@mui/material";
const useStyles = makeStyles({
  noOptions: {
    fontFamily: `"Tajawal", sans-serif`
  }
});

export default function Searchbar(props) {
  const [searchbar, setSearchbar] = useState([{ name: "....." }]);
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    axiosInstace
      .get(
        `/api/courses/v1/courses/search`
      )
      .then((res) => {
        setSearchbar(res.data);

      })
      .catch((err) => console.log(err));
  }, []);



  const styles = useStyles();

  return (
    <>
      {
        isMobile ?
          <Stack className={props.classes} spacing={2}>
            <Autocomplete
              id="free-solo-2-demo"
              open={open}
              onOpen={() => {
                // only open when in focus and inputValue is not empty
                if (inputValue) {
                  setOpen(true);
                }
              }}
              onClose={() => setOpen(false)}
              inputValue={inputValue}
              onInputChange={(e, value, reason) => {
                setInputValue(value);

                // only open when inputValue is not empty after the user typed something
                if (!value) {
                  setOpen(false);
                }
              }}
              autoHighlight
              groupBy={(option) => option.genre}

              value={selected || ""}
              onChange={(e) => {
                setSelected(e.target.textContent);
              }}
              classes={{
                noOptions: styles.noOptions
              }}
              options={searchbar.map((option) => option.name)}
              noOptionsText="لا يوجد دورة تدريبية بهذا الاسم"
              renderInput={(params) => (
                <>
                  <TextField
                    sx={{
                      '& fieldset': {
                        display: "none"
                      }
                    }}
                    placeholder="ابحث عن اي دورة تدريبية"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={
                              !searchbar.find(
                                (itemFound) => itemFound.name === selected
                              )?.course_id
                            }
                          >
                            <a
                              href={`/details/${searchbar.find(
                                (itemFound) => itemFound.name === selected
                              )?.course_id
                                }`}
                            >
                              <SearchIcon color="action" />
                            </a>
                          </IconButton>

                        </InputAdornment>
                      ),
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        const foundCourseId = searchbar.find((itemFound) => itemFound.name === event.target.value)?.course_id;
                        if (foundCourseId) {
                          window.location.assign(`/details/${foundCourseId}`);
                        } else {
                          event.target.blur();

                        }
                      }
                    }}
                  />
                </>
              )}
            />
          </Stack>

          :
          <Stack className={props.classes} spacing={2}>
            <Autocomplete

              id="free-solo-2-demo"
              open={open}
              onOpen={() => {
                // only open when in focus and inputValue is not empty
                if (inputValue) {
                  setOpen(true);
                }
              }}
              onClose={() => setOpen(false)}
              inputValue={inputValue}
              onInputChange={(e, value, reason) => {
                setInputValue(value);

                // only open when inputValue is not empty after the user typed something
                if (!value) {
                  setOpen(false);
                }
              }}
              autoHighlight
              groupBy={(option) => option.genre}

              value={selected || ""}
              onChange={(e) => {
                setSelected(e.target.textContent);
              }}
              classes={{
                noOptions: styles.noOptions
              }}
              options={searchbar.map((option) => option.name)}

              noOptionsText="لا يوجد دورة تدريبية بهذا الاسم"
              renderInput={(params) => (
                <div className="d-flex">
                  <TextField
                    sx={{
                      '& fieldset': {
                        display: "none"
                      },
                      "& .MuiAutocomplete-popupIndicator ": {
                        display: "none"
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        display: "none"
                      }
                    }}

                    placeholder="ابحث عن اي دورة تدريبية"
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      // startAdornment: (
                      //   <InputAdornment  >
                      //     <SearchIcon color="action" />
                      //   </InputAdornment>
                      // ),
                    }}

                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        const foundCourseId = searchbar.find((itemFound) => itemFound.name === event.target.value)?.course_id;
                        if (foundCourseId) {
                          window.location.assign(`/details/${foundCourseId}`);
                        } else {
                          event.target.blur();

                        }
                      }
                    }}

                  />
                  <div className={isMobile ? "mobile-search-button" : "search-button"} style={{ background: "#f8f9fa" }}>
                    <button style={{ color: "#fff" }} disabled={
                      !searchbar.find(
                        (itemFound) => itemFound.name === selected
                      )?.course_id
                    } className="btn btn-primary"><a

                      href={`/details/${searchbar.find(
                        (itemFound) => itemFound.name === selected
                      )?.course_id
                        }`}

                    ><SearchIcon /> </a></button>
                  </div>
                </div>
              )}
            />
          </Stack>
      }



    </>


  );
}
