export const HOST = window.location.origin;
export const APIExperience = "https://experience.eyouthlearning.com";
export const STUDIO_BASE_URL = "https://studio.eyouthlearning.com";
export const ECOMMERCE_URL = "https://ecommerce.experience.eyouthlearning.com";
export const DISCOVERY_URL = "https://discovery.experience.eyouthlearning.com";
export const APIForms = "https://forms.eyouthlearning.com";
export const PROFILE_IMAGES_PREFIX = "https://files.experience.eyouthlearning.com/openedx/openedx"
export const CATEGORIES_LIST = [
    "BANKING",
    "BUSINESS_AND_MANAGEMENT",
    "TECHNOLOGY_SCIENCE",
    "IT_AND_SOFTWARE",
    "SALES",
    "LANGUAGES",
    "SOFT_SKILLS",
    "MEDIA_PHOTOGRAPHY_AND_FILM",
    "TRAINING_AND_DEVELOPMENT",
    "CAREER_DEVELOPMENT",
    "PROGRAMMING_AND_TECHNOLOGY",
    "DESIGNING",
    "HR",
    "FREELANCING",
    "ENTREPRENEURSHIP",
    "MARKETING",
    "OPERATIONS",
    "TOURISM",
    "PR",
    "SCIENCE",
    "OIL_AND_GAS",
    "EDUCATION",
    "HUMANITARIAN_WORK",
    "FOOTBALL_COMMENTARIES",
    "TEACHING_AND_ACADEMICS",
    "PHARMACY",
]

const CONSTANTS = {
    HOST,
    STUDIO_BASE_URL,
    APIExperience,
    APIForms,
    PROFILE_IMAGES_PREFIX,
    CATEGORIES_LIST
}

export default CONSTANTS