import React from 'react'
import styles from "./LoadingImg.module.css"
import logo from "../../assets/learning blue.svg"


function LoadingImg() {
    return (
        <div className={styles.loadingLogo} style={{ buffer: false }}>
            <img width="50%" className={styles['loading-img']} src={logo} />
        </div>
    )
}

export default LoadingImg